<template>
  <div v-if="!$route">loading..</div>
  <div v-else>
    <modals-container />
    <div v-if="!$store.state.isMobile" class="app__desktop">
      <div class="app__desktop-scroll">
        <div v-if="hideNavigation === false">
          <div
            v-if="isFirstgift && bannerOpen && bannerReady"
            class="top__banner-area"
          >
            <section>
              <div
                @click="closeTopBanner()"
                style="
                  width: 60px;
                  margin-left: 1120px;
                  height: 100%;
                  position: absolute;
                "
              ></div>
              <img
                @click="
                  linkToTarget(
                    '/eventdetail?target=202007_event_join_firstgift',
                  )
                "
                class="top__banner"
                srcset="
                  /assets/common/top-navigation/top_banner_new.png    1x,
                  /assets/common/top-navigation/top_banner_new-2x.png 2x
                "
                src="/assets/common/top-navigation/top_banner_new.png"
                width="1180px"
                height="60px"
              />
            </section>
          </div>

          <header
            :style="{
              top: (firstgiftHeader && bannerOpen ? 60 : 0) + 'px',
              position: firstgiftHeader && bannerOpen ? 'unset' : 'fixed',
            }"
          >
            <div class="navigation-container">
              <img
                @click="linkToTarget('/index')"
                src="/assets/common/navigation/logo.svg"
                width="129"
                height="48"
              />
              <ul class="navigation-mainmenu">
                <li
                  :menu-activate="$route.path === '/list'"
                  @click="linkToTarget('/list')"
                >
                  <span>쇼핑하기</span>
                </li>
                <li
                  :menu-activate="$route.path === '/delivery'"
                  @click="linkToTarget('/delivery')"
                >
                  <span>배송안내</span>
                </li>
                <li
                  :menu-activate="$route.path === '/event'"
                  @click="linkToTarget('/event')"
                >
                  <span>이벤트</span>
                </li>
              </ul>
              <div
                style="
                  width: 1px;
                  height: 16px;
                  background-color: white;
                  float: left;
                  margin-top: 17px;
                  margin-left: 24px;
                  margin-right: 24px;
                "
              ></div>
              <div
                @click="openGiftPage()"
                style="
                  display: flex;
                  float: left;
                  margin-top: 10px;
                  cursor: pointer;
                "
              >
                <div style="font-size: 18px; color: white">선물하기</div>
                <img
                  src="/assets/common/new.svg"
                  width="16px"
                  height="16px"
                  style="margin-left: 2px"
                />
              </div>
              <div class="navigation-right">
                <ul class="navigation-submenu">
                  <li @click="linkToTarget('/notify')">공지사항</li>
                  <li @click="linkToTarget('/support')">고객센터</li>
                </ul>
                <div class="navigation-submenu__split"></div>
                <ul v-if="!uid" class="navigation-submenu">
                  <li @click="linkToTarget('/login')">로그인</li>
                  <li @click="linkToTarget('/login?first=true')">회원가입</li>
                </ul>
                <ul v-else class="navigation-submenu">
                  <li @click="linkToTarget('/mypage')">마이페이지</li>
                </ul>

                <div @click="linkToTarget('/cart')" class="navigation-cart">
                  <i></i>
                  <div v-if="uid" class="navigation-cart__count">
                    {{ cartCount + popupCartCount }}
                  </div>
                </div>
                <i @click="sidenavControl()" class="navigation-side"></i>
              </div>
            </div>
          </header>
          <div
            :style="{
              position: firstgiftHeader && bannerOpen ? 'absolute' : 'unset',
              top: firstgiftHeader && bannerOpen ? '-999px' : 'unset',
            }"
            class="navigation-blank"
          ></div>

          <transition name="menu-drawer">
            <nav v-if="sidenavShow" class="menu-drawer__wrap">
              <div @click="sidenavControl" class="menu-drawer__backdrop"></div>
              <section class="menu-drawer__top">
                <h4>메뉴</h4>
                <div class="menu-drawer__top_control">
                  <div @click="linkToTarget('/cart')" class="menu-drawer__cart">
                    <i></i>
                    <div v-if="uid" class="menu-drawer__cart_count">
                      {{ cartCount + popupCartCount }}
                    </div>
                  </div>
                  <i @click="sidenavControl()" class="menu-drawer__close"></i>
                </div>
              </section>

              <section class="menu-drawer__list">
                <p class="menu-drawer__list_title">쇼핑하기</p>
                <ul>
                  <li
                    @click="linkToTarget('/list?tab=' + category.category)"
                    v-for="(category, key) of categoryList"
                    :key="key"
                  >
                    <img
                      v-if="category.event_thumbnail"
                      :src="category.event_thumbnail"
                      width="48px"
                      height="48px"
                      style="
                        display: block;
                        border-radius: 9999px;
                        margin-left: auto;
                        margin-right: auto;
                      "
                    />
                    <category-icon
                      v-else
                      :category="category.icon"
                      :width="48"
                      :height="48"
                      iconColor="#424242"
                    />
                    <p>{{ category.name }}</p>
                    <i v-if="category.isNew"
                      ><img
                        src="/assets/list/new-badge.svg"
                        width="18px"
                        height="18px"
                    /></i>
                  </li>
                </ul>
              </section>

              <div class="menu-drawer__split"></div>

              <section class="menu-drawer__link">
                <h5 @click="linkToTarget('/brandstory')">브랜드스토리</h5>
                <h5 @click="linkToTarget('/guide')">이용가이드</h5>
                <h5 @click="linkToTarget('/notify')">공지사항</h5>
                <h5 @click="linkToTarget('/support?view=faq')">고객센터</h5>
              </section>

              <h5 @click="linkToTarget('/mypage')" class="menu-drawer__mypage">
                마이페이지
              </h5>
            </nav>
          </transition>
        </div>
        <router-view />
      </div>
      <footer v-if="hideNavigation === false">
        <section class="footer-navigation">
          <p @click="linkToTarget('/term')" class="footer-navigation__menu">
            이용약관
          </p>
          <p @click="linkToTarget('/private')" class="footer-navigation__menu">
            <b>개인정보처리방침</b>
          </p>

          <div class="footer-navigation__external">
            <button @click="openApplaunchingMessage()">
              <div>
                <i></i>
                <p>정육각 앱 설치</p>
              </div>
            </button>
            <a
              icon-type="instagram"
              href="https://www.instagram.com/jeongyookgak/"
              target="_blank"
              ><i class="footer-navigation__sns"></i
            ></a>
          </div>
        </section>

        <div class="footer-split"></div>

        <section class="footer-main">
          <article class="footer-main__info">
            <img src="/assets/common/logo.svg" width="82" height="32" />

            <div class="footer-main__info_company">
              <div>
                <span>(주)정육각</span><span>대표이사: 김재연</span
                ><span>|</span
                ><span>주소: 경기도 김포시 고촌읍 아라육로57번길 126</span>
              </div>
              <div>
                <span
                  >사업자등록번호 : 224-87-00271
                  <a
                    href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2248700271&apv_perm_no="
                    target="_blank"
                    >[조회]</a
                  ></span
                ><span>|</span
                ><span>통신판매업신고번호: 2021-경기김포-0668</span>
              </div>
              <div>
                <span>개인정보관리책임자: 박준태(privacy@yookgak.com)</span>
              </div>

              <p>© 2021 Jeongyookgak Inc. All rights reserved.</p>
            </div>
          </article>

          <article class="footer-main__cs">
            <h6>고객센터</h6>
            <h3>1800-0658</h3>
            <p class="footer-main__cs_time">
              월-금요일 : 08:30 - 17:30<br />점심시간 : 12:15 - 13:15<br />(주말
              및 공휴일 휴무)
            </p>
            <div class="footer-main__cs_contact">
              <p>
                이메일:
                <span
                  ><a href="mailto:help@yookgak.com">help@yookgak.com</a></span
                >
              </p>
            </div>
            <div class="footer-main__cs_question">
              <button @click="linkToTarget('/support?view=faq')">
                자주묻는질문
              </button>
              <button @click="linkToTarget('/support?view=question')">
                1:1 문의
              </button>
            </div>
          </article>
        </section>
      </footer>
    </div>

    <div v-else class="app__mobile">
      <div
        @click="linkToTarget('/eventdetail_m?target=202301_event_firstgift')"
        v-if="isFirstgift && hideNavigation === false"
        class="top__banner-mobile"
        :banner-type="'firstgift'"
        :style="{ marginTop: mobileTopBlank - 134 + 'px' }"
      >
        <img
          src="/assets/common/top-navigation/top_banner_mobile_new.png"
          width="310px"
          height="38px"
        />
      </div>
      <div
        v-if="!isFirstgift && !hideNavigation"
        class="top__banner-mobile"
        :banner-type="'applaunching'"
        :style="{ marginTop: mobileTopBlank - 134 + 'px' }"
      >
        <img
          src="/assets/common/top-navigation/applaunching_logo.png"
          width="24px"
          height="24px"
        />
        <p v-if="floatingType === 'new'">앱 전용 12% 쿠폰 받기</p>
        <p v-if="floatingType === 'already'">더 빠르고 편리해진 정육각</p>
        <div @click="closeBanner()" v-if="floatingType === 'new'"></div>
        <button
          id="deeplinking-button-1"
          :style="{ marginRight: floatingType === 'already' ? '16px' : '8px' }"
        >
          <span v-if="floatingType === 'new'">앱 설치</span
          ><span v-if="floatingType === 'already'">앱 열기</span>
        </button>
      </div>
      <header v-if="!hideNavigation">
        <section
          class="navigation-black"
          :style="{ marginTop: mobileTopBlank - 96 + 'px' }"
        >
          <img
            @click="linkToTarget('/index_m')"
            src="/assets/common/navigation/logo.svg"
            width="86"
            height="32"
          />
        </section>
        <section v-if="!isHideUniversal" class="navigation-white">
          <ul>
            <li @click="linkToTarget('/list_m')">쇼핑하기</li>
            <li @click="linkToTarget('/delivery_m')">배송안내</li>
            <li @click="linkToTarget('/event_m')">이벤트</li>
            <li @click="openGiftPage()">
              선물하기<img
                src="/assets/common/new.svg"
                width="14px"
                height="14px"
                style="margin-left: 2px; position: relative; top: 1.5px"
              />
            </li>
          </ul>
        </section>
      </header>
      <div
        v-if="!hideNavigation"
        class="top-nav__blank"
        :style="{
          height: mobileMaxBlank + 'px',
          'margin-top': isHideUniversal ? '-40px' : '0px',
        }"
      ></div>
      <router-view />
      <div v-if="!hideNavigation">
        <!-- Mobile App Download Button -->
        <button
          id="deeplinking-button-2"
          class="footer-installapp"
          v-if="$route.path !== '/friendlink_m'"
        >
          <div>
            <i></i>
            <p>정육각 앱 설치</p>
          </div>
        </button>
        <!-- Mobile App Download Button End -->

        <footer>
          <section class="footer-navigation">
            <p @click="linkToTarget('/term_m')">이용약관</p>
            <p @click="linkToTarget('/private_m')"><b>개인정보처리방침</b></p>

            <div>
              <a
                href="https://www.instagram.com/_u/jeongyookgak/"
                target="_blank"
                ><i icon-type="instagram"></i
              ></a>
            </div>
          </section>

          <div class="footer-split"></div>

          <section class="footer-main">
            <h6>고객센터</h6>
            <h3>
              <a v-if="isServiceCenterOpen" href="tel:1800-0658">1800-0658</a
              ><a v-else @click="serviceCenterAlert()">1800-0658</a>
            </h3>
            <p class="footer-main__cs_time">
              월-금요일 : 08:30 - 17:30<br />점심시간 : 12:15 - 13:15<br />(주말
              및 공휴일 휴무)
            </p>
            <div class="footer-main__cs_contact">
              <p>
                이메일:
                <span
                  ><a href="mailto:help@yookgak.com">help@yookgak.com</a></span
                >
              </p>
            </div>
            <div class="footer-main__cs_question">
              <button @click="linkToTarget('/support_m?view=faq')">
                자주묻는질문
              </button>
              <button @click="linkToTarget('/support_m?view=question')">
                1:1 문의
              </button>
            </div>

            <div class="footer-main__info">
              <img src="/assets/common/logo.svg" width="97" height="36" />
              <div @click="toggleCompanyInfo()">
                <p>
                  사업자 정보 <span v-if="isHideCompanyInfo">열기</span
                  ><span v-else>닫기</span>
                </p>
                <i
                  :style="{
                    transform: isHideCompanyInfo ? 'unset' : 'rotateX(180deg)',
                  }"
                ></i>
              </div>
            </div>

            <div v-if="!isHideCompanyInfo" class="footer-main__company">
              <p>(주)정육각</p>
              <p>대표이사: 김재연</p>
              <p>주소: 경기도 김포시 고촌읍 아라육로57번길 126</p>
              <p>
                사업자등록번호: 224-87-00271
                <a
                  href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2248700271&apv_perm_no="
                  target="_blank"
                  >[조회]</a
                >
              </p>
              <p>통신판매업신고번호: 2021-경기김포-0668</p>
              <p>개인정보관리책임자: 박준태(privacy@yookgak.com)</p>
            </div>

            <p class="footer-main__copyright">
              © 2021 Jeongyookgak Inc. All rights reserved.
            </p>
          </section>
        </footer>
      </div>

      <div v-if="!hideNavigation" class="bottom-navigation__blank"></div>
      <nav class="bottom-navigation" v-if="!hideNavigation">
        <ul>
          <li
            :nav-active="$route.path === '/index_m' && !mobileBottomMenuFlag"
            @click="linkToTarget('/index_m')"
          >
            <i icon-type="home"></i>
            <p>홈</p>
          </li>
          <li :nav-active="mobileBottomMenuFlag" @click="mobileBottomnavOpen()">
            <i icon-type="menu"></i>
            <p>메뉴</p>
          </li>
          <li
            :nav-active="$route.path === '/cart_m' && !mobileBottomMenuFlag"
            @click="linkToTarget('/cart_m')"
          >
            <section>
              <i icon-type="cart"></i>
              <div v-if="uid">
                <span>{{ cartCount + popupCartCount }}</span>
              </div>
            </section>
            <p>장바구니</p>
          </li>
          <li
            :nav-active="
              ($route.path === '/login_m' || $route.path === 'register_m') &&
              !mobileBottomMenuFlag
            "
            @click="linkToTarget('/login_m')"
            v-if="!uid"
          >
            <i icon-type="account"></i>
            <p>로그인/가입</p>
          </li>
          <li
            :nav-active="$route.path === '/mypage_m' && !mobileBottomMenuFlag"
            @click="linkToTarget('/mypage_m')"
            v-else
          >
            <i icon-type="account"></i>
            <p>마이페이지</p>
          </li>
        </ul>
      </nav>
      <section
        id="bottom-menu__wrap"
        :style="{ top: mobileBottomMenuFlag ? '0px' : '115%', height: '100%' }"
      >
        <div class="bottom-menu__container">
          <section class="menu-drawer__top">
            <h4>메뉴</h4>
            <i @click="mobileBottomnavClose()" class="menu-drawer__close"></i>
          </section>

          <section class="menu-drawer__list">
            <p class="menu-drawer__list_title">쇼핑하기</p>
            <ul>
              <li
                @click="linkToTargetNav('/list_m?tab=' + category.category)"
                v-for="(category, key) of categoryList"
                :key="key"
              >
                <img
                  v-if="category.event_thumbnail"
                  :src="category.event_thumbnail"
                  width="48px"
                  height="48px"
                  style="
                    display: block;
                    border-radius: 9999px;
                    margin-left: auto;
                    margin-right: auto;
                  "
                />
                <category-icon
                  v-else
                  :category="category.icon"
                  :width="48"
                  :height="48"
                  iconColor="#424242"
                />
                <p>{{ category.name }}</p>
                <i v-if="category.isNew"
                  ><img
                    src="/assets/list/new-badge.svg"
                    width="14px"
                    height="14px"
                /></i>
              </li>
            </ul>
          </section>

          <div class="menu-drawer__split"></div>

          <section class="menu-drawer__link">
            <h5 @click="linkToTargetNav('/brandstory_m')">브랜드스토리</h5>
            <h5 @click="linkToTargetNav('/guide_m')">이용가이드</h5>
            <h5 @click="linkToTargetNav('/notify_m')">공지사항</h5>
            <h5 @click="linkToTargetNav('/support_m?view=faq')">고객센터</h5>
          </section>
        </div>
      </section>
    </div>

    <div id="cart-icon__fade"></div>
    <spinner :loading="loadingStatus"></spinner>
  </div>
</template>

<script>
import { db, auth, fstore } from './config/firebaseConfig'
import { appService } from './services/app.service'
import { couponService } from './services/coupon.service'

import { dialogOpenFixed, dialogCloseFixed } from './config/dialogFixedConfig'
import ApplaunchingMessage from './components/dialog/event/applaunching/ApplaunchingMessage.vue'

import { openPlainText, openMainPopup } from './services/commonDialog'

import Spinner from './components/common/Spinner.vue'
import CategoryIcon from './components/common/CategoryIcon.vue'
import bus from './utils/bus'

import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import { serverTimeDate } from './return/serverTimeDate'
import { getCookie } from './lib/cookie'

export default {
  name: 'App',
  components: {
    Spinner,
    'category-icon': CategoryIcon,
  },
  data() {
    return {
      uid: null,
      userEmail: '',
      cartCount: 0,
      popupCartCount: 0,
      topnavWhiteShow: false,
      sidenavShow: false,
      mobileTopBlank: 96,
      mobileBottomMenuFlag: false,
      dialogScrollSave: null,
      loadingStatus: false,
      isFirstgift: null,
      mobileMaxBlank: null,
      hideNavigation: false,

      floatingType: null,
      floatingFlag: null,
      floatingCount: 1,
      bannerFlag: true,
      bannerReady: false,
      isHideUniversal: false,
      bannerOpen: true,
      categoryList: null,
      firstgiftHeader: false,
      isHideCompanyInfo: true,
      isServiceCenterOpen: true,
    }
  },

  beforeCreate() {
    if (this.$route.path !== '/') {
      this.sidenavShow = false
      if (this.$store.state.isMobile) {
        if (this.$route.path.indexOf('_m') === -1) {
          this.$router.replace(
            this.$route.path +
              '_m' +
              (this.$route.fullPath.indexOf('?') !== -1
                ? '?' + this.$route.fullPath.split('?')[1]
                : ''),
          )
        }
      } else {
        if (this.$route.path.indexOf('_m') !== -1) {
          this.$router.replace(
            this.$route.path.split('_m')[0] +
              (this.$route.fullPath.indexOf('?') !== -1
                ? '?' + this.$route.fullPath.split('?')[1]
                : ''),
          )
        }
      }
    }
  },

  created() {
    this.isFirstgift = this.$store.getters.getFirstgiftFlag
    if (this.isFirstgift) {
      if (window.pageYOffset <= 60) {
        this.firstgiftHeader = true
      } else {
        this.firstgiftHeader = false
      }
      window.addEventListener('scroll', (e) => {
        this.topnavControl()
      })
    }
    const servertime = serverTimeDate()
    this.isServiceCenterOpen = true
    if (servertime.getDay() !== 0) {
      if (servertime.getHours() > 8 && servertime.getHours() < 18) {
        if (
          (servertime.getHours() === 12 && servertime.getMinutes() > 29) ||
          (servertime.getHours() === 13 && servertime.getMinutes() < 31)
        ) {
          this.isServiceCenterOpen = false
        }

        if (servertime.getHours() === 17 && servertime.getMinutes() > 30) {
          this.isServiceCenterOpen = false
        }
      } else {
        this.isServiceCenterOpen = false
      }
    } else {
      this.isServiceCenterOpen = false
    }

    bus.$on('start:spinner', () => {
      this.loadingStatus = true
    })
    bus.$on('end:spinner', () => {
      this.loadingStatus = false
    })

    bus.$on('add:cart', () => {
      document
        .getElementById('cart-icon__fade')
        .classList.add('cart-icon__fade')
      let cloneIcon = document.getElementById('cart-icon__fade').cloneNode(true)
      document
        .getElementById('cart-icon__fade')
        .parentNode.replaceChild(
          cloneIcon,
          document.getElementById('cart-icon__fade'),
        )
    })
    if (this.$route.query.sec) {
      const secretData = {
        value: this.$route.query.sec,
        timestamp: new Date().getTime(),
      }
      localStorage.setItem('secretQuery', JSON.stringify(secretData))
    }

    if (
      this.$route.path.indexOf('/register') === -1 &&
      this.$route.path.indexOf('/login') === -1 &&
      this.$route.path.indexOf('/pwreset') === -1 &&
      this.$route.path.indexOf('/identify') === -1
    ) {
      this.$store.commit('setRecordRouter', this.$route.fullPath)
    } else {
      this.$store.commit('setRecordRouter', '/index')
    }

    this.$store.commit('setHistoryBack', this.$route)
    this.$store.state.historyFull.unshift(this.$route.fullPath)

    if (this.$store.state.categoryList)
      this.categoryList = sortBy(
        filter(this.$store.state.categoryList, (x) => x.publish && !x.closed),
        ['order'],
      )

    if (this.$route.path.indexOf('/index') !== -1) {
      let popupData = []
      fstore
        .collection('applications/mainpopup/notify')
        .get()
        .then((popupSnaps) => {
          popupSnaps.forEach((popup) => {
            let data = popup.data()
            if (data.publish === true) {
              data.unique_key = popup.id
              popupData.push(data)
            }
          })
          if (popupData.length > 0) {
            if (!getCookie('popupclose_' + popupData[0].unique_key)) {
              openMainPopup(this.$modal, popupData[0], (e) => {
                if (e === 'detail') {
                  this.$router.push(popupData[0].link_web)

                  if (popupData[0].popup_hiding_type === 'today')
                    this.setCookie(
                      'popupclose_' + popupData[0].unique_key,
                      'closed',
                      1,
                    )
                  else if (popupData[0].popup_hiding_type === 'week')
                    this.setCookie(
                      'popupclose_' + popupData[0].unique_key,
                      'closed',
                      7,
                    )
                  else if (popupData[0].popup_hiding_type === 'forever')
                    this.setCookie(
                      'popupclose_' + popupData[0].unique_key,
                      'closed',
                      9999,
                    )
                } else if (e === 'today')
                  this.setCookie(
                    'popupclose_' + popupData[0].unique_key,
                    'closed',
                    1,
                  )
                else if (e === 'week')
                  this.setCookie(
                    'popupclose_' + popupData[0].unique_key,
                    'closed',
                    7,
                  )
                else if (e === 'forever')
                  this.setCookie(
                    'popupclose_' + popupData[0].unique_key,
                    'closed',
                    9999,
                  )
              })
            }
          }
        })
    }

    if (
      this.$route.path.indexOf('/naver-signin-redirect') !== -1 ||
      this.$route.path.indexOf('/giftcard') !== -1 ||
      this.$route.path.indexOf('/appeventdetail') !== -1 ||
      this.$route.path.indexOf('/naverpay') !== -1 ||
      this.$route.path.indexOf('/appnotice') !== -1 ||
      this.$route.path.indexOf('/livez') !== -1 ||
      this.$route.fullPath.indexOf('target=202008_event_kakaopay') !== -1
    )
      this.hideNavigation = true

    this.$store.state.webGtag['webutm_source'] = this.$route.query.utm_source
      ? this.$route.query.utm_source
      : null
    this.$store.state.webGtag['webutm_campaign'] = this.$route.query
      .utm_campaign
      ? this.$route.query.utm_campaign
      : null
    this.$store.state.webGtag['webutm_medium'] = this.$route.query.utm_medium
      ? this.$route.query.utm_medium
      : null
    this.$store.state.webGtag['webutm_content'] = this.$route.query.utm_content
      ? this.$route.query.utm_content
      : null
    this.$store.state.webGtag['webutm_term'] = this.$route.query.utm_term
      ? this.$route.query.utm_term
      : null

    this.$store.state.webGtag['webga'] = getCookie('_ga')

    try {
      /* eslint-disable-next-line */
      Kakao.init('38028b4b8b77959a349b71e22f5cfbab')
    } catch (e) {
      console.log(e)
    }
    auth.onAuthStateChanged((user) => {
      this.bannerReady = true
      if (user) {
        this.uid = user.uid
        this.userEmail = user.email ? user.email.toLowerCase().trim() : ''
        db.ref('customers/' + this.uid + '/cart').on('value', (cartSnaps) => {
          this.cartCount = 0
          if (cartSnaps.val()) {
            for (let productKey in cartSnaps.val()) {
              this.cartCount += cartSnaps.val()[productKey].count
            }
          }
        })
        db.ref('customers/' + this.uid + '/popupcart').on(
          'value',
          (cartSnaps) => {
            this.popupCartCount = 0
            if (cartSnaps.val()) {
              for (let productKey in cartSnaps.val()) {
                this.popupCartCount += cartSnaps.val()[productKey].count
              }
            }
          },
        )

        couponService
          .findUserCouponByKey(this.uid, 'applaunch_discount_15')
          .then((result) => {
            if (!getCookie('appcancel')) this.floatingFlag = true

            if (result) {
              this.floatingType = 'already'
            } else {
              this.floatingType = 'new'
            }
          })

        /* eslint-disable-next-line */
        gtag('config', 'UA-84539077-2', {
          user_id: this.uid,
        })

        airbridge.setUserID(this.uid)
      } else {
        this.uid = null
        // this.$router.push('/index')
        gtag('config', 'UA-84539077-2')
      }

      this.firstGiftCheck()
    })
    ga('send', 'pageview', this.$route.fullPath)
  },

  watch: {
    $route(to, from) {
      if (to.query.sec) {
        const secretData = {
          value: to.query.sec,
          timestamp: new Date().getTime(),
        }
        localStorage.setItem('secretQuery', JSON.stringify(secretData))
      }
    },
    '$route.name'() {
      this.sidenavShow = false
      document.body.style.overflow = 'auto'
      if (this.$route.path !== '/') {
        if (this.$store.state.isMobile) {
          if (this.mobileBottomMenuFlag) {
            this.mobileBottomMenuFlag = false
            dialogCloseFixed(this.dialogScrollSave)
          }
          if (this.$route.path.indexOf('_m') === -1) {
            this.$router.replace(
              this.$route.path +
                '_m' +
                (this.$route.fullPath.indexOf('?') !== -1
                  ? '?' + this.$route.fullPath.split('?')[1]
                  : ''),
            )
          }
        } else {
          if (this.$route.path.indexOf('_m') !== -1) {
            this.$router.replace(
              this.$route.path.split('_m')[0] +
                (this.$route.fullPath.indexOf('?') !== -1
                  ? '?' + this.$route.fullPath.split('?')[1]
                  : ''),
            )
          }
        }
      }
      window.scrollTo(0, 0)
      if (
        this.$route.path.indexOf('/register') === -1 &&
        this.$route.path.indexOf('/login') === -1 &&
        this.$route.path.indexOf('/welcome') === -1
      ) {
        this.$store.commit('setRecordRouter', this.$route.fullPath)
      }
      if (this.$route.path.indexOf('/appbridge') === -1) {
        this.$store.commit('setHistoryBack', this.$route)
      }

      if (!this.uid) {
        if (this.floatingCount > 0) {
          if (!getCookie('appcancel')) this.floatingFlag = true
          if (getCookie('okcashbag') || getCookie('paycoexp'))
            this.floatingFlag = false
          this.floatingType = 'new'
        }

        if (this.floatingCount > 1) {
          this.floatingFlag = false
        }

        this.floatingCount++
      }

      if (
        this.$route.path.indexOf('/appeventdetail') !== -1 ||
        this.$route.path.indexOf('/appnotice') !== -1 ||
        this.$route.fullPath.indexOf('target=202008_event_kakaopay') !== -1
      )
        this.hideNavigation = true
      else this.hideNavigation = false

      kakaoPixel('3964345357815794168').pageView()
    },
    '$route.fullPath'() {
      ga('send', 'pageview', this.$route.fullPath)

      if (
        this.$route.fullPath.indexOf(
          '/eventdetail_m?target=202007_event_join_firstgift',
        ) !== -1 ||
        this.$route.fullPath.indexOf(
          '/eventdetail_m?target=202007_event_signin_firstgift',
        ) !== -1
      ) {
        this.isHideUniversal = true
      } else {
        this.isHideUniversal = false
      }
      if (this.$route.fullPath !== this.$store.state.historyFull[0]) {
        this.$store.state.historyFull.unshift(this.$route.fullPath)
      }

      // 모달로 인한 스크롤 락 체크
      const bodyClass = document.body.classList
      if (
        bodyClass.contains('v--modal-block-scroll') &&
        (bodyClass.contains('v--modal-scroll-fixed') ||
          bodyClass.contains('v--modal-scroll-fixed-mobile'))
      ) {
        bodyClass.remove('v--modal-block-scroll')
        bodyClass.remove('v--modal-scroll-fixed')
        bodyClass.remove('v--modal-scroll-fixed-mobile')
      }
      this.setAirbridgeDeeplink()
    },
    '$store.state.categoryList'() {
      if (this.$store.state.categoryList)
        this.categoryList = sortBy(
          filter(this.$store.state.categoryList, (x) => x.publish && !x.closed),
          ['order'],
        )
    },
    '$store.getters.getFirstgiftFlag'() {
      this.isFirstgift = this.$store.getters.getFirstgiftFlag

      if (this.isFirstgift) {
        if (window.pageYOffset <= 60) {
          this.firstgiftHeader = true
        } else {
          this.firstgiftHeader = false
        }
        window.addEventListener('scroll', (e) => {
          this.topnavControl()
        })
      }
    },
  },

  methods: {
    openGiftPage() {
      let winOpen = window.open()
      if (auth.currentUser) {
        auth.currentUser.getIdToken().then((idtoken) => {
          appService.verifyCustomToken(idtoken, 'pc').then((result) => {
            if (result.code === '0000') {
              winOpen.location.href =
                'https://gift.jeongyookgak.com?token=' + result.custom_token
            } else {
              winOpen.location.href = 'https://gift.jeongyookgak.com'
            }
          })
        })
      } else {
        winOpen.location.href = 'https://gift.jeongyookgak.com'
      }
    },
    addKakaoChannelChat() {
      /* eslint-disable-next-line */
      Kakao.Channel.chat({
        channelPublicId: '_sKxgkM',
      })
    },

    serviceCenterAlert() {
      openPlainText(this.$modal, '', '고객센터 운영시간이 아닙니다.')
    },

    async firstGiftCheck() {
      let uid = this.uid
      let due = 30
      await this.$store.dispatch('checkFirstgiftTarget', { uid, due })
      this.isFirstgift = this.$store.getters.getFirstgiftFlag

      this.mobileMaxBlank = this.bannerFlag ? 134 : 96

      this.mobileTopBlank = this.mobileMaxBlank

      this.$nextTick(() => {
        this.setAirbridgeDeeplink()
      })

      if (!this.$store.state.isMobile) {
        // window.addEventListener('scroll', (e) => {
        //   this.topnavControl();
        // });
      } else {
        window.addEventListener('scroll', (e) => {
          let st = window.pageYOffset
          if (st > 80) {
            this.mobileTopBlank = 40
          } else {
            this.mobileTopBlank = this.mobileMaxBlank
          }
        })
      }
    },

    closeTopBanner() {
      this.bannerOpen = false
    },

    topnavControl() {
      if (window.pageYOffset <= 60) {
        this.firstgiftHeader = true
      } else {
        this.firstgiftHeader = false
      }
    },
    toggleCompanyInfo() {
      this.isHideCompanyInfo = !this.isHideCompanyInfo
    },
    sidenavControl() {
      this.sidenavShow = !this.sidenavShow
    },
    mobileBottomnavOpen() {
      this.dialogScrollSave = window.scrollY
      this.mobileBottomMenuFlag = true
      dialogOpenFixed(this.dialogScrollSave)
    },

    mobileBottomnavClose() {
      this.mobileBottomMenuFlag = false
      dialogCloseFixed(this.dialogScrollSave)
    },

    receiveSubscription() {
      let email = document.getElementById('subscription__email').value
      if (email.indexOf('@') !== -1 && email.indexOf('.') !== -1) {
        email = email.replace(/\./g, ':')
        /* eslint-disable-next-line */
        email = email.replace(/\@/g, '::')

        db.ref('mailinglist/' + email)
          .set(new Date().toISOString())
          .then(() => {
            openPlainText(
              this.$modal,
              '감사합니다',
              '이제 정육각의 소식을 이메일로\n받아보실 수 있습니다!',
            )
          })

        document.getElementById('subscription__email').value = ''
      } else {
        openPlainText(
          this.$modal,
          '등록 실패',
          '정확한 이메일 주소를 입력해주세요.',
        )
      }
    },

    linkToTarget(target) {
      this.$router.push(target)
      this.sidenavShow = false
    },

    linkToTargetNav(target) {
      this.$router.push(target)
      this.mobileBottomnavClose()
    },

    setAirbridgeDeeplink() {
      let appLink
      if (this.$route.path === '/index_m') appLink = 'jeongyookgak://main'
      else if (this.$route.path === '/list_m') appLink = 'jeongyookgak://main'
      else if (this.$route.path === '/brandstory_m')
        appLink = 'jeongyookgak://main'
      else if (this.$route.path === '/review_m') appLink = 'jeongyookgak://main'
      else if (this.$route.path === '/term_m') appLink = 'jeongyookgak://main'
      else if (this.$route.path === '/private_m')
        appLink = 'jeongyookgak://main'
      else if (this.$route.path === '/support_m')
        appLink = 'jeongyookgak://main'
      else if (
        this.$route.path === '/eventdetail_m' &&
        this.$route.query.target === '202302_event_birthday-saleall'
      )
        appLink = 'jeongyookgak://event?key=J1CAXV5ttZuL0F0HKmCd'
      else if (this.$route.path === '/notify_m')
        appLink = 'jeongyookgak://noticelist'
      else if (this.$route.path === '/notice_m')
        appLink = 'jeongyookgak://notice?key=' + this.$route.query.target
      else if (this.$route.path === '/guide_m') appLink = 'jeongyookgak://guide'
      else if (this.$route.path === '/mypage_m') {
        if (this.$route.query.view === 'point')
          appLink = 'jeongyookgak://pointlist'
        else if (this.$route.query.view === 'coupon')
          appLink = 'jeongyookgak://couponlist'
        else appLink = 'jeongyookgak://recordlist'
      } else if (this.$route.path === '/cart_m') appLink = 'jeongyookgak://cart'
      else if (this.$route.path === '/detail_m')
        appLink = 'jeongyookgak://detail?key=' + this.$route.query.product
      else appLink = 'jeongyookgak://main'

      airbridge.setDeeplinks({
        buttonID: 'deeplinking-button-1',
        deeplinks: {
          ios: appLink,
          android: appLink,
          desktop: 'http://www.jeongyookgak.com',
        },
        fallbacks: {
          ios: 'itunes-appstore',
          android: 'google-play',
        },
        defaultParams: {
          campaign: 'appbanner',
          medium: 'internal',
          term: '',
          content: '',
        },
        desktopPopUp: true,
      })

      airbridge.setDeeplinks({
        buttonID: 'deeplinking-button-2',
        deeplinks: {
          ios: appLink,
          android: appLink,
          desktop: 'http://www.jeongyookgak.com',
        },
        fallbacks: {
          ios: 'itunes-appstore',
          android: 'google-play',
        },
        defaultParams: {
          campaign: 'appbanner',
          medium: 'internal',
          term: '',
          content: '',
        },
        desktopPopUp: true,
      })
    },

    openApplaunchingMessage() {
      let dialogScrollSave = window.scrollY
      this.$modal.show(
        ApplaunchingMessage,
        {
          modal: this.$modal,
        },
        {
          name: 'applauncing-message',
          width: '500px',
          height: 'auto',
          transition: 'nice-modal-fade',
          scrollable: true,
        },
        {
          'before-open': (result) => {
            dialogOpenFixed(dialogScrollSave)
          },
          closed: (result) => {
            dialogCloseFixed(dialogScrollSave)
          },
        },
      )
    },

    setCookie(name, value, exp) {
      let date = new Date()
      date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000)
      document.cookie =
        name + '=' + value + ';expires=' + date.toUTCString() + ';path=/'
    },

    cancelFloating() {
      this.setCookie('appcancel', 'check', 1)
      this.floatingFlag = false
    },

    linkFloating() {
      if (
        this.$route.fullPath.indexOf(
          '/detail_m?product=uGajnybjHfLQmojc8sM0',
        ) === -1 &&
        this.$route.fullPath.indexOf(
          '/detail_m?product=rQEhyw0kms2pExZ64JLR',
        ) === -1
      ) {
        this.$router.push('/appbridge_m')
      }
    },

    closeBanner() {
      this.bannerFlag = false
      this.mobileMaxBlank = this.bannerFlag ? 134 : 96
      this.mobileTopBlank = this.mobileMaxBlank
      this.$store.state.bannerFlag = false
    },

    gaTracking(category, action) {
      ga('send', 'event', category, action)
    },
  },
}
</script>

<style lang="scss">
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');
@import './styles/variable';
@import './styles/common';

.paycheck_dialog {
  top: 24px !important;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
}

.display-coupon_dialog {
  top: calc(100% - 400px) !important;
  background-color: white;
}

.app__desktop-scroll {
  min-height: 710px;
}

.v--modal-scroll-fixed {
  overflow-y: scroll !important;
  position: fixed !important;
  width: 100% !important;
}

.v--modal-scroll-fixed-mobile {
  overflow: hidden !important;
}
.v--modal-overlay {
  background: rgba(0, 0, 0, 0.65) !important;
  height: 100% !important;

  .v--modal-box {
    overflow: visible !important;
  }
}

.checkbox__form {
  width: 16px;
  height: 16px;
  border: 2px solid #979797;
  border-radius: 50%;
  cursor: pointer;

  .checkbox__checked {
    width: 10px;
    height: 10px;
    background-color: $jyg-red;
    border-radius: 50%;
    margin-left: 3px;
    margin-top: 3px;
  }
}

.dialog-close__btn {
  width: 40px;
  height: 40px;
  border: none;
  background: url(/assets/common/dialog/close.svg) no-repeat;
  margin-left: calc(100% - 20px);
  margin-top: -20px;
  position: relative;
  z-index: 1;
}

.dialog-close__mobile-btn {
  width: 32px;
  height: 32px;
  border: none;
  background-color: black;
  margin-left: calc(100% - 32px);

  i {
    display: block;
    width: 16px;
    height: 16px;
    background: url(/assets/common/dialog/mobile-close.svg) no-repeat;
    margin-left: 8px;
  }
}

* {
  font-family: 'Spoqa Han Sans', 'Sans-serif';
  outline: 0;
  font-display: swap;
}

body,
p,
ul,
input,
h1,
h2,
h3,
h4,
h5,
h6,
button,
pre {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

ul {
  list-style: none;
}

$a-tags: 'a, a:active, a:hover, a:visited';

#{$a-tags} {
  color: inherit;
  text-decoration: none;
}

#mobile-dialog__shippingdate {
  .v--modal-box {
    bottom: 0px;
    position: absolute;
    top: unset !important;
    left: 0px !important;
  }
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  margin-right: 8px !important;
  background-color: #d2d2d2 !important;
  opacity: 1 !important;

  &:nth-last-child(1) {
    margin-right: 0px;
  }
}

.swiper-pagination-bullet-active {
  background-color: #2f2f2f !important;
}

.freshnew-carousel-pagination,
.freshapple-carousel-pagination,
.freshpear-carousel-pagination,
.samsam-carousel-pagination {
  .swiper-pagination-bullet {
    width: 14px !important;
    height: 14px !important;
    margin-right: 0px !important;
    background-color: #e0e0e0 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #9e9e9e !important;
  }
}

.recommend-carousel-pagination {
  .swiper-pagination-bullet {
    background-color: #f3f4f5 !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #c1c1c1 !important;
  }
}

.app__desktop {
  .tooltip-icon {
    width: 16px;
    height: 16px;
    background: url(/assets/common/icon/tooltip.svg) no-repeat;
    background-size: cover;
    display: block;
  }

  .app__desktop-scroll {
    .top__banner-area {
      background-color: #70cbf1;
      cursor: pointer;
      width: 100%;
      position: relative;
      height: 60px;
      min-width: 1184px;

      section {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 1180px;

        img {
          object-fit: cover;
        }
      }
    }

    header {
      width: 100%;
      min-width: 1184px;
      height: 96px;
      background-color: #000000;
      color: white;
      position: fixed;
      top: 0px;
      z-index: 10;

      .navigation-container {
        width: 1184px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 24px;

        &::after {
          clear: both;
          content: '';
          display: block;
        }

        img {
          float: left;
          cursor: pointer;
        }

        .navigation-mainmenu {
          float: left;
          margin-left: 34px;
          margin-top: 16px;

          &::after {
            clear: both;
            content: '';
            display: block;
          }

          li {
            float: left;
            padding-left: 5px;
            padding-right: 5px;
            margin-right: 14px;
            font-size: 18px;
            line-height: 18px;
            cursor: pointer;

            &:last-child {
              margin-right: 0px;
            }

            &[menu-activate='true'] {
              font-weight: bold;

              span {
                border-bottom: 1px solid white;
              }
            }
          }
        }

        .navigation-right {
          float: right;

          &::after {
            clear: both;
            content: '';
            display: block;
          }

          .navigation-submenu {
            float: left;
            margin-top: 18px;

            &::after {
              clear: both;
              content: '';
              display: block;
            }

            li {
              float: left;
              margin-left: 12px;
              font-size: 14px;
              line-height: 18px;
              cursor: pointer;

              &:first-child {
                margin-left: 0px;
              }
            }
          }

          .navigation-submenu__split {
            float: left;
            margin-left: 20px;
            margin-right: 20px;
            width: 1px;
            height: 12px;
            margin-top: 22px;
            background-color: white;
          }

          .navigation-cart {
            float: left;
            margin-left: 24px;
            cursor: pointer;

            &::after {
              clear: both;
              content: '';
              display: block;
            }

            div {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #ed0000;
              line-height: 24px;
              text-align: center;
              font-size: 14px;
              float: left;
              margin-left: -24px;
            }

            i {
              width: 48px;
              height: 48px;
              display: block;
              background: url('/assets/common/navigation/cart.svg') no-repeat;
              background-size: cover;
              float: left;
            }
          }

          .navigation-side {
            float: left;
            margin-left: 8px;
            width: 48px;
            height: 48px;
            display: block;
            background: url('/assets/common/navigation/side.svg') no-repeat;
            background-size: cover;
            cursor: pointer;
          }
        }
      }
    }

    .navigation-blank {
      width: 100%;
      height: 96px;
    }

    .top-nav__wrap {
      width: 100%;
      height: 129px;
      background-color: black;
      border-bottom: 1px solid #303030;
      min-width: 1180px;

      .top-nav__container {
        @extend %full-container;
        overflow: hidden;

        .router-link__block {
          display: block;
        }

        .top-nav__logo {
          display: block;
          width: 169.05px;
          height: 66px;
          margin-top: 32px;
          background-image: url(/assets/common/top-navigation/logo.svg);
          background-repeat: no-repeat;
          background-size: cover;
          float: left;
        }

        .top-nav__list {
          float: right;
          text-align: right;

          %nav-link-init {
            display: block;
            float: left;
            cursor: pointer;
            text-align: center;
            &:last-of-type {
              margin-right: 0px;
            }
          }

          .top-nav-list__top {
            margin-top: 30px;
            overflow: hidden;
            display: inline-block;

            .top-nav-list__top_link {
              @extend %nav-link-init;
              width: 64px;
              height: 25px;
              line-height: 25px;
              margin-right: 17px;

              .top-nav-list__top_text {
                font-size: 14px;
                color: #737373;
              }

              &:hover {
                .top-nav-list__top_text {
                  border-bottom: 1px solid #737373;
                  padding-bottom: 1px;
                }
              }
            }

            .top-nav-list__top_split {
              float: left;
              width: 1px;
              height: 13px;
              background-color: #737373;
              margin-right: 11px;
              margin-top: 7px;
            }
          }

          .top-nav-list__bottom {
            margin-top: 11px;
            overflow: hidden;
            display: inline-block;

            .top-nav-list__bottom_link {
              @extend %nav-link-init;
              width: 100px;
              height: 40px;
              line-height: 40px;
              margin-right: 37px;

              .top-nav-list__bottom_text {
                font-size: 18px;
                font-weight: bold;
                color: #f7f7f7;
                position: relative;
                top: 3px;
              }

              &:hover {
                .top-nav-list__bottom_text {
                  border-bottom: 1px solid #f7f7f7;
                  padding-bottom: 1px;
                }
              }

              .top-nav-list__bottom_cartnum {
                @extend %main-cartnum;
              }

              .top-nav-list__bottom_cart {
                @extend %main-cart;
                background-image: url(/assets/common/top-navigation/gnb_cart_default.svg);
              }

              .top-nav-list__bottom_menu {
                @extend %main-menu;
                background-image: url(/assets/common/top-navigation/gnb_menu.svg);
              }
            }

            .top-nav-list__bottom_active {
              .top-nav-list__bottom_text {
                border-bottom: 1px solid #f7f7f7;
                padding-bottom: 1px;
              }
            }
          }
        }
      }
    }

    .top-nav__wrap-darkgray {
      background-color: #1c1c1c;
    }

    .top-nav__wrap-gray {
      background-color: #292929;
    }

    .top-nav__wrap-borderblack {
      border-bottom: 1px solid black;
    }

    .top-white__wrap {
      width: 100%;
      height: 80px;
      position: fixed;
      top: 0px;
      -webkit-box-shadow:
        0 14px 300px rgba(0, 0, 0, 0.25),
        0 10px 50px rgba(0, 0, 0, 0.22);
      box-shadow:
        0 14px 300px rgba(0, 0, 0, 0.25),
        0 10px 50px rgba(0, 0, 0, 0.22);
      background-color: white;
      z-index: 15;

      .top-white__container {
        overflow: hidden;
        margin-top: 20px;
        @extend %full-container;

        .top-white__logo {
          width: 34px;
          height: 39px;
          background: url(/assets/common/top-navigation/submenu_logo.svg)
            no-repeat;
          float: left;
          cursor: pointer;
        }

        .top-white__list {
          float: left;
          overflow: hidden;
          margin-left: 231.9px;

          .top-white__link {
            margin-left: 35px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            float: left;
            font-size: 18px;
            font-weight: bold;
          }
        }

        .top-white__gnb {
          float: right;
          overflow: hidden;

          .top-white__cart {
            width: 74px;
            height: 40px;
            overflow: hidden;
            float: left;
            cursor: pointer;

            .top-white__cartnum {
              @extend %main-cartnum;
            }

            .top-white__cart-icon {
              @extend %main-cart;
              background: url(/assets/common/top-navigation/submenu_cart.svg)
                no-repeat;
            }
          }

          .top-white__menu {
            width: 51px;
            height: 40px;
            overflow: hidden;
            float: left;
            margin-left: 10px;
            cursor: pointer;

            .top-white__menu-icon {
              @extend %main-menu;
              background: url(/assets/common/top-navigation/submenu_menu.svg)
                no-repeat;
            }
          }
        }
      }
    }

    .top-white__animation-enter-active {
      animation: slide-down 0.2s;
    }

    .top-white__animation-leave-active {
      animation: slide-down 0.2s reverse;
    }

    @keyframes slide-down {
      0% {
        top: -80px;
      }
      50% {
        top: -40px;
      }
      100% {
        top: 0px;
      }
    }

    .menu-drawer-enter-active {
      animation: slide-side 0.2s ease-out;
    }

    .menu-drawer-leave-active {
      animation: slide-side 0.2s ease-out reverse;
    }

    @keyframes slide-side {
      0% {
        right: -480px;
      }
      30% {
        right: -240px;
      }
      50% {
        right: -150px;
      }
      70% {
        right: -50px;
      }
      100% {
        right: 0px;
      }
    }

    nav {
      width: 480px;
      height: 100%;
      background-color: white;
      z-index: 15;
      position: fixed;
      right: 0px;
      top: 0px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 50px 0px;
      overflow: auto;

      .menu-drawer__backdrop {
        width: calc(100% - 480px);
        height: 100%;
        position: fixed;
        z-index: 15;
        left: 0px;
        top: 0px;
      }

      .menu-drawer__top {
        margin: {
          top: 16px;
          left: 25px;
          right: 16px;
        }

        &::after {
          clear: both;
          content: '';
          display: block;
        }

        h4 {
          float: left;
          font-size: 24px;
          margin-top: 6px;
        }

        .menu-drawer__top_control {
          float: right;
          &::after {
            clear: both;
            content: '';
            display: block;
          }

          .menu-drawer__cart {
            float: left;
            cursor: pointer;

            &::after {
              clear: both;
              content: '';
              display: block;
            }

            div {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #ed0000;
              line-height: 24px;
              text-align: center;
              font-size: 14px;
              float: left;
              margin-left: -24px;
              color: white;
            }

            i {
              width: 48px;
              height: 48px;
              display: block;
              background: url('/assets/common/menu/cart.svg') no-repeat;
              background-size: cover;
              float: left;
            }
          }

          .menu-drawer__close {
            width: 48px;
            height: 48px;
            display: block;
            background: url('/assets/common/menu/close.svg') no-repeat;
            background-size: cover;
            float: left;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }

      .menu-drawer__list {
        margin-top: 32px;

        .menu-drawer__list_title {
          font-size: 18px;
          margin-left: 25px;
          margin-right: 16px;
        }

        ul {
          &::after {
            clear: both;
            content: '';
            display: block;
          }
          margin-top: 5px;
          margin-left: 8px;

          li {
            float: left;
            width: 104px;
            height: 104px;
            padding-top: 24px;
            margin-right: 16px;
            cursor: pointer;
            position: relative;

            svg {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }

            p {
              text-align: center;
              margin-top: 8px;
              font-size: 18px;
              color: #616161;
            }

            i {
              position: absolute;
              top: 16px;
              right: 16px;
              display: block;
            }

            &:nth-child(4n) {
              margin-right: 0px;
            }

            &:hover {
              border-radius: 8px;
              background-color: rgba(224, 224, 224, 0.29);
            }
          }
        }
      }

      .menu-drawer__split {
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
        margin-top: 23px;
      }

      .menu-drawer__link {
        margin: {
          top: 32px;
          left: 25px;
          right: 16px;
        }

        h5 {
          display: block;
          font-size: 18px;
          margin-bottom: 21px;
          cursor: pointer;
        }
      }

      .menu-drawer__mypage {
        margin: {
          top: 40px;
          left: 25px;
          right: 16px;
        }

        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  footer {
    margin-top: 150px;
    border-top: 1px solid #bdbdbd;

    .footer-navigation {
      width: 1184px;
      margin: {
        top: 11px;
        left: auto;
        right: auto;
      }

      &::after {
        clear: both;
        content: '';
        display: block;
      }

      & > a {
        float: left;
        margin-right: 23px;
        font-size: 14px;
        margin-top: 10px;
      }

      .footer-navigation__menu {
        float: left;
        margin-right: 23px;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        cursor: pointer;
      }

      .footer-navigation__external {
        float: right;

        &::after {
          clear: both;
          content: '';
          display: block;
        }

        button {
          float: left;
          width: 128px;
          height: 40px;
          background-color: #fafafa;
          border: 1px solid #eeeeee;
          border-radius: 4px;
          cursor: pointer;

          div {
            display: inline-block;

            i {
              float: left;
              width: 24px;
              height: 24px;
              background: url(/assets/common/footer/install_app.svg) no-repeat;
              background-size: cover;
              display: block;
              margin-top: 4px;
            }

            p {
              float: left;
              font-size: 13px;
              color: #212121;
              letter-spacing: -0.41px;
              margin-top: 6px;
            }
          }
        }

        a {
          float: left;
          display: block;
          margin-top: 8px;

          &[icon-type='kakao'] {
            margin-left: 24px;

            i {
              background: url(/assets/common/footer/kakao.svg) no-repeat;
              background-size: cover;
            }
          }

          &[icon-type='instagram'] {
            margin-left: 16px;

            i {
              background: url(/assets/common/footer/instagram.svg) no-repeat;
              background-size: cover;
            }
          }

          .footer-navigation__sns {
            width: 24px;
            height: 24px;
            display: block;
            cursor: pointer;
          }
        }
      }
    }

    .footer-split {
      width: 100%;
      height: 1px;
      margin-top: 11px;
      background-color: #eeeeee;
    }

    .footer-main {
      width: 1184px;
      margin: {
        top: 8px;
        left: auto;
        right: auto;
        bottom: 19px;
      }

      &::after {
        clear: both;
        content: '';
        display: block;
      }

      .footer-main__info {
        float: left;
        margin-top: 16px;

        img {
          display: block;
        }

        .footer-main__info_company {
          margin-top: 16px;
          color: #212121;

          div {
            margin-bottom: 4px;
            line-height: 19px;
            font-size: 13px;

            &::after {
              clear: both;
              content: '';
              display: block;
            }

            span {
              display: block;
              float: left;
              margin-right: 8px;

              a {
                color: #4bb8ce;
                cursor: pointer;
                padding-right: 3px;
              }
            }
          }

          p {
            font-size: 12px;
            margin-top: 29px;
          }
        }
      }

      .footer-main__cs {
        margin-top: 10px;
        float: right;
        text-align: right;

        h6 {
          font-size: 14px;
        }

        h3 {
          font-size: 24px;
          margin-top: -9px;
        }

        .footer-main__cs_time {
          font-size: 12px;
          line-height: 15px;
          color: #757575;
          margin-top: -5px;
        }

        .footer-main__cs_contact {
          margin-top: 9px;
          p {
            display: block;
            font-size: 13px;

            span {
              color: #4bb8ce;
            }
          }
        }

        .footer-main__cs_question {
          margin-top: 10px;

          &::after {
            clear: both;
            content: '';
            display: block;
          }

          button {
            float: left;
            width: 88px;
            height: 32px;
            border: 1px solid #212121;
            border-radius: 4px;
            background-color: white;
            font-size: 13px;
            display: block;
            cursor: pointer;

            &:first-child {
              margin-left: 64px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.app__mobile {
  .tooltip-icon {
    width: 14px;
    height: 14px;
    background: url(/assets/common/icon/tooltip.svg) no-repeat;
    background-size: cover;
    display: block;
  }
  .top__banner-mobile {
    position: fixed;
    top: 0px;
    z-index: 11;
    width: 100%;
    height: 38px;
    transition: margin-top 0.2s;
    -webkit-transition: margin-top 0.2s;

    &[banner-type='firstgift'] {
      background-color: #70cbf1;

      img {
        width: 310px;
        margin-left: calc(50% - 155px);
        object-fit: cover;
      }
    }

    &[banner-type='applaunching'] {
      background-color: white;

      img {
        float: left;
        margin-left: 12px;
        margin-top: 7px;
      }

      p {
        float: left;
        line-height: 38px;
        margin-left: 9px;
        font-size: 14px;
      }

      button {
        float: right;
        width: 64px;
        height: 24px;
        border-radius: 14px;
        background-color: #0a84fe;
        color: white;
        border: none;
        font-size: 14px;
        font-weight: bold;
        margin-right: 16px;
        margin-top: 7px;
      }

      div {
        width: 20px;
        height: 20px;
        background: url('/assets/common/top-navigation/applaunching_close.svg')
          no-repeat;
        background-size: cover;
        display: block;
        float: right;
        margin-top: 9px;
        margin-right: 12px;
      }
    }
  }

  header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;

    .navigation-black {
      width: 100%;
      height: 56px;
      background-color: #000000;
      position: relative;
      transition: margin-top 0.2s;
      -webkit-transition: margin-top 0.2s;

      img {
        display: block;
        margin-left: 12px;
        top: 12px;
        position: relative;
      }
    }

    .navigation-white {
      width: 100%;
      height: 39px;
      background-color: white;
      border-bottom: 1px solid #eeeeee;

      ul {
        &::after {
          clear: both;
          content: '';
          display: block;
        }

        li {
          float: left;
          width: 25%;
          height: 39px;
          line-height: 39px;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }

  .top-nav__wrap {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 10;

    .top-nav__black {
      width: 100%;
      height: 60px;
      transition: margin-top 0.2s;
      -webkit-transition: margin-top 0.2s;
      background-color: #000;

      .top-nav__logo {
        width: 82px;
        height: 32px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 14px;
        background: url(/assets/common/top-navigation/logo_m.svg) no-repeat;
      }
    }

    .top-nav__menu {
      width: 100%;
      height: 40px;
      background-color: #fff;
      border-bottom: 1px solid silver;

      .top-nav__menu-text {
        float: left;
        font-size: 15px;
        color: black;
        line-height: 40px;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .top-nav__blank {
    transition: height 0.2s;
    -webkit-transition: height 0.2s;
  }

  .footer-installapp {
    width: 91%;
    height: 48px;
    margin-left: 4.5%;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #fafafa;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    display: block;
    cursor: pointer;

    div {
      display: inline-block;

      i {
        float: left;
        width: 24px;
        height: 24px;
        background: url(/assets/common/footer/install_app.svg) no-repeat;
        background-size: cover;
        display: block;
        margin-top: 5px;
      }

      p {
        float: left;
        font-size: 14px;
        color: #212121;
        letter-spacing: -0.44px;
        margin-top: 6px;
      }
    }
  }

  footer {
    width: 100%;
    border-top: 1px solid #bdbdbd;

    .footer-navigation {
      width: 91%;
      margin-left: 4.5%;
      margin-top: 11px;

      &::after {
        clear: both;
        content: '';
        display: block;
      }

      & > a {
        float: left;
        font-size: 13px;
        margin-top: 4px;
        margin-right: 16px;
      }

      p:not(:last-child) {
        float: left;
        font-size: 13px;
        margin-top: 4px;
        margin-right: 16px;
      }

      div {
        float: right;
        &::after {
          clear: both;
          content: '';
          display: block;
        }

        a {
          display: block;
          float: left;

          i {
            width: 24px;
            height: 24px;
            display: block;

            &[icon-type='kakao'] {
              background: url(/assets/common/footer/kakao.svg) no-repeat;
              background-size: cover;
            }

            &[icon-type='instagram'] {
              margin-left: 16px;
              background: url(/assets/common/footer/instagram.svg) no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }

    .footer-split {
      margin-top: 12px;
      width: 100%;
      height: 1px;
      background-color: #eeeeee;
    }

    .footer-main {
      width: 91%;
      margin-left: 4.5%;

      h6 {
        margin-top: 7px;
        margin-left: 2px;
        font-size: 14px;
        font-weight: bold;
      }

      h3 {
        margin-top: -8px;
        font-size: 28px;
        color: #4bb8ce;
      }

      .footer-main__cs_time {
        margin-left: 2px;
        font-size: 13px;
        line-height: 18px;
        color: #757575;
      }

      .footer-main__cs_contact {
        margin-top: 13px;
        margin-left: 2px;

        p {
          font-size: 14px;
          margin-bottom: 3px;

          span {
            color: #4bb8ce;
          }
        }
      }

      .footer-main__cs_question {
        margin-top: 11px;

        &::after {
          clear: both;
          content: '';
          display: block;
        }

        button {
          float: left;
          width: 62px;
          height: 32px;
          border: 1px solid #212121;
          border-radius: 4px;
          background-color: white;
          font-size: 13px;
          display: block;
          cursor: pointer;
          color: black;

          &:first-child {
            width: 91px;
            margin-right: 8px;
          }
        }
      }

      .footer-main__info {
        width: 100%;
        margin-top: 20px;

        &::after {
          clear: both;
          content: '';
          display: block;
        }

        img {
          float: left;
          display: block;
          margin-left: -3px;
        }

        div {
          float: right;
          margin-top: 8px;

          &::after {
            clear: both;
            content: '';
            display: block;
          }

          p {
            float: left;
            font-size: 14px;
            color: #212121;
          }

          i {
            float: left;
            width: 16px;
            height: 16px;
            background: url(/assets/common/footer/mobile/arrow.svg) 0 0 / cover
              no-repeat;
            margin-left: 4px;
            margin-top: 3px;
          }
        }
      }

      .footer-main__company {
        width: 100%;
        margin-top: 12px;
        padding-bottom: 7px;

        a {
          color: #4bb8ce;
        }

        p {
          font-size: 13px;
          color: #757575;
          margin-bottom: 4px;
        }
      }

      .footer-main__copyright {
        margin-top: 12px;
        margin-bottom: 5px;
        font-size: 12px;
        color: #9e9e9e;
      }
    }
  }

  .bottom-navigation__blank {
    width: 100%;
    height: calc(56px + env(safe-area-inset-bottom));
    height: calc(56px + constant(safe-area-inset-bottom));
  }
  .bottom-navigation {
    position: fixed;
    bottom: 0px;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    width: 100%;
    height: 55px;
    background-color: white;
    border-top: 1px solid #e0e0e0;
    z-index: 12;

    ul {
      &::after {
        clear: both;
        content: '';
        display: block;
      }

      li {
        float: left;
        width: 25%;
        height: 55px;

        section {
          position: relative;
          width: 24px;
          height: 24px;
          display: block;
          margin-left: auto;
          margin-right: auto;

          div {
            position: absolute;
            width: 12px;
            height: 12px;
            color: white;
            font-weight: bold;
            background-color: #ed0000;
            border-radius: 50%;
            text-align: center;
            line-height: 12px;
            top: 0px;
            right: 0px;

            span {
              font-size: 10px;
              -webkit-transform: scale(0.7);
              display: block;
              transform: scale(0.7);
            }
          }
        }

        i {
          display: block;
          width: 24px;
          height: 24px;
          margin: {
            top: 7px;
            left: auto;
            right: auto;
          }

          @each $icon in 'home', 'menu', 'cart', 'account' {
            &[icon-type='#{$icon}'] {
              background: url(/assets/common/navigation/mobile/#{$icon}.svg)
                0
                0 /
                cover
                no-repeat;
            }
          }
        }

        p {
          font-size: 13px;
          text-align: center;
        }

        &[nav-active='true'] {
          i {
            @each $icon in 'home', 'menu', 'cart', 'account' {
              &[icon-type='#{$icon}'] {
                background: url(/assets/common/navigation/mobile/#{$icon}-active.svg)
                  0
                  0 /
                  cover
                  no-repeat;
              }
            }
          }

          p {
            color: #e92d44;
          }
        }
      }
    }
  }

  .bottom-nav__wrap {
    width: 100%;
    height: 50px;
    background-color: #000;
    position: fixed;
    bottom: 0;
    z-index: 12;
    overflow-y: scroll;

    .bottom-nav__menu {
      float: left;
      width: 25%;

      .bottom-nav__icon-home {
        width: 18px;
        height: 17px;
        background: url(/assets/common/bottom-navigation/home.svg) no-repeat;
        margin: {
          left: auto;
          right: auto;
          top: 10px;
        }
        display: block;
      }

      .bottom-nav__icon-menu {
        width: 17px;
        height: 17px;
        background: url(/assets/common/bottom-navigation/menu.svg) no-repeat;
        margin: {
          left: auto;
          right: auto;
          top: 10px;
        }
        display: block;
      }

      .bottom-nav__cart-data {
        width: 17px;
        height: 17px;
        background-color: #d0021b;
        border-radius: 50%;
        float: left;
        margin-top: 10px;
        position: relative;
        left: calc(37% - 17px);
        line-height: 16.5px;
        color: white;
        text-align: center;
        font-size: 10px;

        span {
          position: relative;
          left: -0.5px;
        }
      }

      .bottom-nav__icon-cart {
        width: 22px;
        height: 19px;
        background: url(/assets/common/bottom-navigation/cart.svg) no-repeat;
        margin: {
          left: auto;
          right: auto;
          top: 8px;
        }
        position: relative;
        left: 3px;
        display: block;
      }

      .bottom-nav__icon-auth {
        width: 31px;
        height: 19px;
        background: url(/assets/common/bottom-navigation/auth.svg) no-repeat;
        margin: {
          left: auto;
          right: auto;
          top: 8px;
        }
        display: block;
      }

      .bottom-nav__text {
        font-size: 11px;
        text-align: center;
        color: #fff;
        height: 20px;
        line-height: 20px;
      }
    }
  }

  #bottom-menu__wrap {
    background-color: #ffffff;
    position: fixed;
    z-index: 15;
    width: 100%;

    transition: top 0.4s ease-in-out;
    -webkit-transition: top 0.4s ease-in-out;

    overflow-y: scroll;

    .bottom-menu__container {
      width: 100%;
      padding-bottom: 50px;

      .menu-drawer__top {
        margin: {
          top: 16px;
          left: 16px;
          right: 16px;
        }

        &::after {
          clear: both;
          content: '';
          display: block;
        }

        h4 {
          float: left;
          font-size: 24px;
        }

        .menu-drawer__close {
          width: 32px;
          height: 32px;
          display: block;
          background: url('/assets/common/menu/close.svg') no-repeat;
          background-size: cover;
          float: right;
          cursor: pointer;
        }
      }

      .menu-drawer__list {
        margin-top: 28px;

        .menu-drawer__list_title {
          margin: {
            top: 16px;
            left: 16px;
            right: 16px;
          }
          font-size: 18px;
        }

        ul {
          margin: 32px 16px;
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          gap: 24px;

          li {
            grid-column: span 1 / span 1;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;
            position: relative;

            svg {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }

            p {
              text-align: center;
              margin-top: 4px;
              font-size: 16px;
              color: #616161;
            }

            i {
              position: absolute;
              top: 4px;
              right: 4px;
              display: block;
            }
          }
        }
      }

      .menu-drawer__split {
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
        margin-top: -8px;
      }

      .menu-drawer__link {
        margin: {
          top: 32px;
          left: 16px;
          right: 16px;
        }

        h5 {
          display: block;
          font-size: 18px;
          margin-bottom: 24px;
          cursor: pointer;
        }
      }

      .menu-drawer__mypage {
        margin: {
          top: 40px;
          left: 25px;
          right: 16px;
        }

        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  .bottom-floating__app {
    position: fixed;
    width: 100%;
    bottom: 50px;
    z-index: 17;

    img {
      display: block;
    }

    .bottom-floating__link {
      &[floating='new'] {
        width: 36%;
        height: 25%;
        position: absolute;
        top: 59%;
        right: 9.5%;
      }

      &[floating='already'] {
        width: 35%;
        height: 28%;
        position: absolute;
        top: 53%;
        right: 11%;
      }
    }

    .bottom-floating__cancel {
      &[floating='new'] {
        width: 13%;
        height: 29%;
        position: absolute;
        top: 8.2%;
        right: 0px;
      }

      &[floating='already'] {
        width: 13%;
        height: 37%;
        position: absolute;
        top: 14%;
        right: 0px;
      }
    }
  }

  .freshnew-carousel-pagination,
  .freshapple-carousel-pagination,
  .freshbenefit-carousel-pagination,
  .freshpear-carousel-pagination,
  .samsam-carousel-pagination {
    .swiper-pagination-bullet {
      width: 8px !important;
      height: 8px !important;
      margin-right: 0px !important;
      background-color: #e0e0e0 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: #9e9e9e !important;
    }
  }

  .freshnew-carousel-pagination {
    .swiper-pagination-bullet {
      &:last-child {
        // display: none;
      }
    }
  }
}

.cart-icon__fade {
  width: 200px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  background: url(/assets/common/icon/cart-fade.svg) no-repeat;
  -webkit-animation: fadeinout 1.2s linear forwards;
  animation: fadeinout 1.2s linear forwards;
  opacity: 0;
  position: fixed;
  z-index: -1111;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
    z-index: 1111;
    -webkit-transform: translate3d(0, -33%, 0);
    transform: translate3d(0, -33%, 0);
  }
  15% {
    opacity: 1;
    z-index: 1111;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  85% {
    opacity: 1;
    z-index: 1111;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    z-index: -1111;
    -webkit-transform: translate3d(0, 50% 0);
    transform: translate3d(0, 50%, 0);
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
    z-index: 1111;
    -webkit-transform: translate3d(0, -33%, 0);
    transform: translate3d(0, -33%, 0);
  }
  15% {
    opacity: 1;
    z-index: 1111;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  85% {
    opacity: 1;
    z-index: 1111;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    z-index: -1111;
    -webkit-transform: translate3d(0, 50% 0);
    transform: translate3d(0, 50%, 0);
  }
}
</style>
