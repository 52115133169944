<template>
  <div>
    <modals-container />
    <section>
      <div v-if="mainBannerList" style="position: relative; min-width: 1280px">
        <div
          class="embla embla_main"
          style="width: 100%; margin-left: auto; margin-right: auto"
        >
          <div class="embla__container" style="gap: 0px">
            <div
              class="embla__slide"
              v-for="(banner, i) of mainBannerList"
              style="flex: 0 0 100%"
              :key="i"
            >
              <HomeLinkButton :link="banner.webLink">
                <img :src="banner.pcImageUrl" width="100%" />
              </HomeLinkButton>
            </div>
          </div>
        </div>
        <button
          class="main-arrow"
          @click="mainScrollPrev()"
          style="left: 200px"
        >
          <img
            src="/assets/index/mainbanner-arrow.svg"
            width="40px"
            height="40px"
          />
        </button>
        <button
          class="main-arrow"
          @click="mainScrollNext()"
          style="right: 200px"
        >
          <img
            src="/assets/index/mainbanner-arrow.svg"
            width="40px"
            height="40px"
            style="transform: rotateY(180deg)"
          />
        </button>
        <div
          style="
            position: absolute;
            bottom: 32px;
            right: 368px;
            padding: 4px 12px;
            border-radius: 9999px;
            background: rgba(0, 0, 0, 0.3);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.32px;
            color: white;
            text-align: center;
            min-width: 33px;
          "
        >
          {{ mainBannerIndex }} / {{ mainBannerList.length }}
        </div>
      </div>
      <div
        v-else
        style="
          width: 1920px;
          height: 420px;
          margin-left: auto;
          margin-right: auto;
          background-color: #f5f5f5;
        "
      ></div>
    </section>
    <section style="padding: 40px 0px">
      <h1
        style="
          font-size: 34px;
          font-weight: 700;
          letter-spacing: -0.68px;
          color: #212121;
          width: 1184px;
          margin-left: auto;
          margin-right: auto;
          display: block;
        "
      >
        <span style="color: #d7223c">초신선</span> 식재료 둘러보기
      </h1>
      <div
        class="list-category"
        style="
          margin-top: 24px;
          display: flex;
          padding: 0px 60px;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          width: 1184px;
          height: 131px;
          margin-left: auto;
          margin-right: auto;
          border-radius: 8px;
          background-color: #f5f5f5;
        "
      >
        <!-- :href="`/list?tab=${category.category}`" -->
        <template v-for="(category, key) of categoryList">
          <button
            v-if="!category.closed"
            :key="key"
            @click="linkToList(category.category)"
          >
            <img
              v-if="category.event_thumbnail"
              :src="category.event_thumbnail"
              width="60px"
              height="60px"
              style="display: block; border-radius: 9999px"
            />
            <div v-else>
              <category-icon
                :category="category.icon"
                :width="38"
                :height="38"
                :iconColor="'#424242'"
              />
              <i v-if="category.isNewBadge"
                ><img
                  src="/assets/list/new-badge.svg"
                  width="20px"
                  height="20px"
              /></i>
            </div>
            <p>{{ category.name }}</p>
          </button>
          <a v-else :key="key" style="cursor: default">
            <div style="background-color: #f0fbfb">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
              >
                <g clip-path="url(#clip0_1281_19523)">
                  <path
                    d="M19.8562 10.0616C19.8562 6.56341 17.5579 3.59361 14.392 2.5783C14.3597 1.28839 13.3028 0.25 12.006 0.25C10.7092 0.25 9.65693 1.28608 9.62232 2.57138C6.44716 3.57977 4.13963 6.55648 4.13963 10.0616L4.14886 13.6337L2.49666 16.495C2.46205 16.555 2.46205 16.6312 2.49666 16.6912L3.14969 17.8219C3.18431 17.8818 3.24892 17.9211 3.32045 17.9211H8.59547C8.5747 18.0757 8.56086 18.2303 8.56086 18.3895C8.56086 20.2909 10.1069 21.837 12.0083 21.837C13.6213 21.837 14.9781 20.7201 15.3496 19.2202H13.9789C13.6144 20.0786 12.7052 20.6532 11.6876 20.5055C10.6699 20.3578 9.9523 19.541 9.87615 18.5626C9.85769 18.3434 9.87615 18.1288 9.91999 17.9234H20.6962C20.7654 17.9234 20.83 17.8865 20.8669 17.8242L21.52 16.6935C21.5546 16.6335 21.5546 16.5573 21.52 16.4973L19.8678 13.6337L19.8585 10.0616H19.8562ZM12.0083 1.55837C12.4744 1.55837 12.869 1.85835 13.019 2.27371C12.6844 2.22986 12.3452 2.20448 11.9991 2.20448C11.6529 2.20448 11.3253 2.22755 10.9976 2.2714C11.1476 1.85835 11.5422 1.55837 12.0083 1.55837ZM3.97348 16.5942L5.47799 13.989L5.46876 10.0616C5.46876 6.46188 8.39702 3.53131 11.9991 3.53131C15.6011 3.53131 18.5294 6.45957 18.5294 10.0639L18.5386 13.9914L20.0408 16.5942H3.97348Z"
                    fill="#29C2CE"
                  />
                  <path
                    d="M21.6447 4.80013L21.264 4.26709L20.2002 5.02858L20.5809 5.56162C21.3863 6.68769 21.8247 8.0699 21.8132 9.4475L21.8085 10.1005L23.1169 10.1098L23.1215 9.45673C23.1331 7.80223 22.6093 6.14773 21.6447 4.80013Z"
                    fill="#29C2CE"
                  />
                  <path
                    d="M3.41871 5.56162L3.79945 5.02858L2.73568 4.26709L2.35494 4.80013C1.39039 6.15004 0.866579 7.80454 0.878116 9.45904L0.882731 10.1121L2.1911 10.1028L2.18649 9.44981C2.17495 8.0699 2.61338 6.69 3.41871 5.56392V5.56162Z"
                    fill="#29C2CE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1281_19523">
                    <rect
                      width="22.2446"
                      height="21.587"
                      fill="white"
                      transform="translate(0.87793 0.25)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p
                style="
                  font-size: 10px;
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  color: #29c2ce;
                  margin-top: 2.6px;
                "
              >
                {{ category.closed_message }}
              </p>
            </div>
            <p>{{ category.name }}</p>
          </a>
        </template>
      </div>
    </section>
    <div v-if="curationList">
      <div v-for="(curation, i) of curationList" :key="i">
        <div
          v-if="curation.type === 'home-curation-carousel-image'"
          style="
            padding: 40px 0px;
            width: 1184px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <HomeLinkButton :link="curation.data.webLink">
            <img
              :src="curation.data.pcImageUrl"
              width="1184px"
              height="270px"
              style="display: block; margin-bottom: 24px"
            />
          </HomeLinkButton>
          <div style="position: relative">
            <div :class="`embla embla${i}`">
              <div class="embla__container">
                <div
                  class="embla__slide"
                  v-for="(item, j) in curation.data.items"
                  :key="j"
                >
                  <div
                    @click="linkToDetail(item.id)"
                    style="grid-column: span 1 / span 1; cursor: pointer"
                  >
                    <div
                      :style="`width: 278px; height: 278px; display: flex; justify-content: center; align-items: center; background-color: #f5f5f5; border-radius: 8px; position: relative;`"
                    >
                      <div
                        v-if="item.block_cart"
                        style="
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.36);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          z-index: 5;
                          border-radius: 8px;
                        "
                      >
                        <p
                          style="
                            color: white;
                            font-size: 18px;
                            font-weight: 700;
                          "
                        >
                          일시품절
                        </p>
                      </div>
                      <img
                        :src="item.list_thumbnail"
                        width="198px"
                        height="198px"
                      />
                      <img
                        @click.stop="openCartDialog(item.id)"
                        src="/assets/eventDetail/template/cart.svg"
                        width="60px"
                        height="60px"
                        style="
                          filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                          cursor: pointer;
                          position: absolute;
                          bottom: 16px;
                          right: 16px;
                        "
                      />
                    </div>
                    <div style="margin-top: 16px">
                      <div v-if="item.list_tag" style="margin-bottom: 6px">
                        <div
                          style="
                            padding: 4px 6px;
                            border-radius: 3px;
                            font-size: 12px;
                            font-weight: 500;
                            color: white;
                            background-color: #06b8c8;
                            border: 1px solid #06b8c8;
                            display: inline-block;
                          "
                        >
                          {{ item.list_tag }}
                        </div>
                      </div>
                      <p :style="`font-size: 16px; color: #212121;`">
                        {{ item.name }} {{ item.usage }} {{ item.weight
                        }}{{ item.weighttype }}
                      </p>
                      <div style="margin-top: 10px">
                        <p
                          v-if="item.preprice"
                          style="
                            margin-bottom: 2px;
                            text-decoration: line-through;
                            color: rgba(33, 33, 33, 0.4);
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ item.preprice | priceForm }}원
                        </p>
                        <p
                          style="
                            font-size: 20px;
                            font-weight: 600;
                            display: flex;
                            gap: 2px;
                          "
                        >
                          <span style="color: #f02c49" v-if="item.preprice"
                            >{{
                              Math.floor(
                                (1 - item.price / item.preprice).toFixed(3) *
                                  100,
                              )
                            }}%</span
                          >
                          <span style="color: #212121"
                            >{{ item.price | priceForm }}원</span
                          >
                        </p>
                        <div
                          style="
                            display: flex;
                            gap: 2px;
                            margin-top: 8px;
                            align-items: center;
                          "
                        >
                          <img
                            v-if="
                              todayDeliveryGoodsList.includes(item.id) ||
                              tomorrowDeliveryGoodsList.includes(item.id)
                            "
                            src="/assets/index/bolt.svg"
                          />
                          <img
                            v-if="
                              todayDeliveryGoodsList.includes(item.id) ||
                              tomorrowDeliveryGoodsList.includes(item.id)
                            "
                            src="/assets/index/nextDayDelivery.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="curation-arrow"
              @click="scrollPrev(i)"
              style="left: -80px"
            >
              <img
                src="/assets/index/curation-arrow.svg"
                width="48px"
                height="48px"
              />
            </button>
            <button
              class="curation-arrow"
              @click="scrollNext(i)"
              style="right: -80px"
            >
              <img
                src="/assets/index/curation-arrow.svg"
                width="48px"
                height="48px"
                style="transform: rotateY(180deg)"
              />
            </button>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-eventbanner-primary'"
          style="
            padding: 40px 0px;
            width: 1184px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <div
            style="
              width: 1184px;
              height: 120px;
              border-radius: 6px;
              background: #f2fafb;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 24px;
            "
          >
            <img :src="curation.data.pcImageUrl" width="90px" height="90px" />
            <div style="width: 451px">
              <p
                style="
                  color: #616161;
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: -0.16px;
                "
              >
                {{ curation.data.subtitle }}
              </p>
              <h3
                style="
                  margin-top: 6px;
                  color: #212121;
                  font-size: 24px;
                  font-weight: 600;
                  letter-spacing: -0.24px;
                "
              >
                {{ curation.data.pcTitle }}
              </h3>
            </div>
            <HomeLinkButton :link="curation.data.webLink">
              <span class="redirect-button-mint">바로가기</span>
            </HomeLinkButton>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-curation-carousel-basic'"
          style="
            padding: 40px 0px;
            width: 1184px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <HomeLinkButton :link="curation.data.webLink">
            <div style="display: flex; align-items: center; gap: 8px">
              <h2
                style="
                  color: #212121;
                  font-size: 30px;
                  font-weight: 700;
                  letter-spacing: -0.3px;
                "
              >
                {{ curation.data.title }}
              </h2>
              <button class="curation-redirect-arrow">
                <img
                  src="/assets/index/curation-redirect.svg"
                  width="32px"
                  height="32px"
                />
              </button>
            </div>
          </HomeLinkButton>
          <p
            style="
              margin-top: 8px;
              color: #757575;
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.18px;
            "
          >
            {{ curation.data.description }}
          </p>

          <div style="position: relative; margin-top: 24px">
            <div :class="`embla embla${i}`">
              <div class="embla__container">
                <div
                  class="embla__slide"
                  v-for="(item, j) in curation.data.items"
                  :key="j"
                >
                  <div
                    @click="linkToDetail(item.id)"
                    style="grid-column: span 1 / span 1; cursor: pointer"
                  >
                    <div
                      :style="`width: 278px; height: 278px; display: flex; justify-content: center; align-items: center; background-color: #f5f5f5; border-radius: 8px; position: relative;`"
                    >
                      <div
                        v-if="item.block_cart"
                        style="
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.36);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          z-index: 5;
                          border-radius: 8px;
                        "
                      >
                        <p
                          style="
                            color: white;
                            font-size: 18px;
                            font-weight: 700;
                          "
                        >
                          일시품절
                        </p>
                      </div>
                      <img
                        :src="item.list_thumbnail"
                        width="198px"
                        height="198px"
                      />
                      <img
                        @click.stop="openCartDialog(item.id)"
                        src="/assets/eventDetail/template/cart.svg"
                        width="60px"
                        height="60px"
                        style="
                          filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                          cursor: pointer;
                          position: absolute;
                          bottom: 16px;
                          right: 16px;
                        "
                      />
                    </div>
                    <div style="margin-top: 16px">
                      <div v-if="item.list_tag" style="margin-bottom: 6px">
                        <div
                          :style="`padding: 4px 6px; display: inline-block; border-radius: 3px; font-size: 12px; font-weight: 500; color: white; background-color: #06B8C8; border: 1px solid #06B8C8`"
                        >
                          {{ item.list_tag }}
                        </div>
                      </div>
                      <p :style="`font-size: 16px; color: #212121;`">
                        {{ item.name }} {{ item.usage }} {{ item.weight
                        }}{{ item.weighttype }}
                      </p>
                      <div style="margin-top: 10px">
                        <p
                          v-if="item.preprice"
                          style="
                            margin-bottom: 2px;
                            text-decoration: line-through;
                            color: rgba(33, 33, 33, 0.4);
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ item.preprice | priceForm }}원
                        </p>
                        <p
                          style="
                            font-size: 20px;
                            font-weight: 600;
                            display: flex;
                            gap: 2px;
                          "
                        >
                          <span style="color: #f02c49" v-if="item.preprice"
                            >{{
                              Math.floor(
                                (1 - item.price / item.preprice).toFixed(3) *
                                  100,
                              )
                            }}%</span
                          >
                          <span style="color: #212121"
                            >{{ item.price | priceForm }}원</span
                          >
                        </p>
                        <div
                          style="
                            display: flex;
                            gap: 2px;
                            margin-top: 8px;
                            align-items: center;
                          "
                        >
                          <img
                            v-if="
                              todayDeliveryGoodsList.includes(item.id) ||
                              tomorrowDeliveryGoodsList.includes(item.id)
                            "
                            src="/assets/index/bolt.svg"
                          />
                          <img
                            v-if="
                              todayDeliveryGoodsList.includes(item.id) ||
                              tomorrowDeliveryGoodsList.includes(item.id)
                            "
                            src="/assets/index/nextDayDelivery.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="curation-arrow"
              @click="scrollPrev(i)"
              style="left: -80px"
            >
              <img
                src="/assets/index/curation-arrow.svg"
                width="48px"
                height="48px"
              />
            </button>
            <button
              class="curation-arrow"
              @click="scrollNext(i)"
              style="right: -80px"
            >
              <img
                src="/assets/index/curation-arrow.svg"
                width="48px"
                height="48px"
                style="transform: rotateY(180deg)"
              />
            </button>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-curation-single'"
          style="padding: 40px 0px"
        >
          <div
            style="
              width: 100%;
              height: 480px;
              background-color: #f2fafb;
              padding: 40px 0px;
              box-sizing: border-box;
            "
          >
            <div style="width: 1184px; margin-left: auto; margin-right: auto">
              <HomeLinkButton :link="curation.data.webLink">
                <div style="display: flex; align-items: center; gap: 8px">
                  <h2
                    style="
                      color: #212121;
                      font-size: 30px;
                      font-weight: 700;
                      letter-spacing: -0.3px;
                    "
                  >
                    {{ curation.data.title }}
                  </h2>
                  <img
                    src="/assets/index/curation-redirect.svg"
                    width="32px"
                    height="32px"
                  />
                </div>
              </HomeLinkButton>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 24px;
                "
              >
                <img
                  :src="curation.data.pcImageUrl"
                  width="480px"
                  height="340px"
                  style="object-fit: cover"
                />
                <div
                  style="
                    width: 680px;
                    height: 340px;
                    border-radius: 8px;
                    background-color: white;
                    box-sizing: border-box;
                    padding: 32px;
                  "
                >
                  <p
                    style="
                      color: #212121;
                      text-align: center;
                      font-size: 20px;
                      font-weight: 500;
                      letter-spacing: -0.2px;
                      opacity: 0.8;
                    "
                  >
                    {{ curation.data.description }}
                  </p>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background: #d9d9d9;
                      margin-top: 24px;
                    "
                  ></div>

                  <div
                    style="
                      display: flex;
                      gap: 30px;
                      align-items: center;
                      margin-top: 47px;
                    "
                  >
                    <a
                      :href="`/detail?product=${curation.linkValue.goodslistkey}`"
                      style="
                        width: 180px;
                        height: 180px;
                        border-radius: 7.2px;
                        background-color: #f5f5f5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        :src="curation.data.listitem.list_thumbnail"
                        width="124px"
                        height="124px"
                      />
                    </a>
                    <a
                      :href="`/detail?product=${curation.linkValue.goodslistkey}`"
                      style="flex: 1"
                    >
                      <div
                        v-if="curation.data.listitem.list_tag"
                        style="margin-bottom: 6px"
                      >
                        <div
                          style="
                            padding: 4px 6px;
                            display: inline-block;
                            border-radius: 3px;
                            font-size: 12px;
                            font-weight: 500;
                            color: white;
                            background-color: #06b8c8;
                            border: 1px solid #06b8c8;
                          "
                        >
                          {{ curation.data.listitem.list_tag }}
                        </div>
                      </div>
                      <p>
                        {{ curation.data.listitem.name }}
                        {{ curation.data.listitem.usage }}
                        {{ curation.data.listitem.weight
                        }}{{ curation.data.listitem.weighttype }}
                      </p>
                      <div style="margin-top: 10px">
                        <p
                          v-if="curation.data.listitem.preprice"
                          style="
                            margin-bottom: 2px;
                            text-decoration: line-through;
                            color: rgba(33, 33, 33, 0.4);
                            font-size: 14px;
                            font-weight: 400;
                          "
                        >
                          {{ curation.data.listitem.preprice | priceForm }}원
                        </p>
                        <p
                          style="
                            font-size: 20px;
                            font-weight: 600;
                            display: flex;
                            gap: 2px;
                          "
                        >
                          <span
                            style="color: #f02c49"
                            v-if="curation.data.listitem.preprice"
                            >{{
                              Math.floor(
                                (
                                  1 -
                                  curation.data.listitem.price /
                                    curation.data.listitem.preprice
                                ).toFixed(3) * 100,
                              )
                            }}%</span
                          >
                          <span style="color: #212121"
                            >{{
                              curation.data.listitem.price | priceForm
                            }}원</span
                          >
                        </p>
                        <div
                          style="
                            display: flex;
                            gap: 2px;
                            margin-top: 8px;
                            align-items: center;
                          "
                        >
                          <img
                            v-if="
                              todayDeliveryGoodsList.includes(
                                curation.data.listitem.id,
                              ) ||
                              tomorrowDeliveryGoodsList.includes(
                                curation.data.listitem.id,
                              )
                            "
                            src="/assets/index/bolt.svg"
                          />
                          <img
                            v-if="
                              todayDeliveryGoodsList.includes(
                                curation.data.listitem.id,
                              ) ||
                              tomorrowDeliveryGoodsList.includes(
                                curation.data.listitem.id,
                              )
                            "
                            src="/assets/index/nextDayDelivery.svg"
                          />
                        </div>
                      </div>
                    </a>
                    <img
                      @click.stop="openCartDialog(curation.data.listitem.id)"
                      src="/assets/eventDetail/template/cart.svg"
                      width="60px"
                      height="60px"
                      style="
                        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                        cursor: pointer;
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="curation.type === 'home-curation-list'"
          style="
            padding: 40px 0px;
            width: 1184px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <h2
            style="
              color: #212121;
              font-size: 30px;
              font-weight: 700;
              letter-spacing: -0.3px;
            "
          >
            {{ curation.data.title }}
          </h2>
          <p
            style="
              margin-top: 8px;
              color: #757575;
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.18px;
            "
          >
            {{ curation.data.description }}
          </p>

          <img
            :src="curation.data.pcImageUrl"
            width="100%"
            height="270px"
            style="margin-top: 24px; object-fit: cover; display: block"
          />

          <ul
            style="
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              gap: 24px;
              margin-top: 24px;
            "
          >
            <li
              v-for="(item, j) in curation.data.items"
              :key="j"
              @click="linkToDetail(item.id)"
              style="
                grid-column: span 1 / span 1;
                display: flex;
                gap: 30px;
                align-items: center;
                cursor: pointer;
              "
            >
              <div
                style="
                  width: 180px;
                  height: 180px;
                  border-radius: 7.2px;
                  background-color: #f5f5f5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div
                  :style="`width: 180px; height: 180px; display: flex; justify-content: center; align-items: center; background-color: #f5f5f5; border-radius: 8px; position: relative;`"
                >
                  <div
                    v-if="item.block_cart"
                    style="
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background: rgba(0, 0, 0, 0.36);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      z-index: 5;
                      border-radius: 8px;
                    "
                  >
                    <p style="color: white; font-size: 18px; font-weight: 700">
                      일시품절
                    </p>
                  </div>
                  <img
                    :src="item.list_thumbnail"
                    width="124px"
                    height="124px"
                  />
                </div>
              </div>
              <div style="flex: 1">
                <div v-if="item.list_tag" style="margin-bottom: 6px">
                  <div
                    style="
                      padding: 4px 6px;
                      border-radius: 3px;
                      font-size: 12px;
                      font-weight: 500;
                      color: white;
                      background-color: #06b8c8;
                      border: 1px solid #06b8c8;
                      display: inline-block;
                    "
                  >
                    {{ item.list_tag }}
                  </div>
                </div>
                <p>
                  {{ item.name }} {{ item.usage }} {{ item.weight
                  }}{{ item.weighttype }}
                </p>
                <div style="margin-top: 10px">
                  <p
                    v-if="item.preprice"
                    style="
                      margin-bottom: 2px;
                      text-decoration: line-through;
                      color: rgba(33, 33, 33, 0.4);
                      font-size: 14px;
                      font-weight: 400;
                    "
                  >
                    {{ item.preprice | priceForm }}원
                  </p>
                  <p
                    style="
                      font-size: 20px;
                      font-weight: 600;
                      display: flex;
                      gap: 2px;
                    "
                  >
                    <span style="color: #f02c49" v-if="item.preprice"
                      >{{
                        Math.floor(
                          (1 - item.price / item.preprice).toFixed(3) * 100,
                        )
                      }}%</span
                    >
                    <span style="color: #212121"
                      >{{ item.price | priceForm }}원</span
                    >
                  </p>
                  <div
                    style="
                      display: flex;
                      gap: 2px;
                      margin-top: 8px;
                      align-items: center;
                    "
                  >
                    <img
                      v-if="
                        todayDeliveryGoodsList.includes(item.id) ||
                        tomorrowDeliveryGoodsList.includes(item.id)
                      "
                      src="/assets/index/bolt.svg"
                    />
                    <img
                      v-if="
                        todayDeliveryGoodsList.includes(item.id) ||
                        tomorrowDeliveryGoodsList.includes(item.id)
                      "
                      src="/assets/index/nextDayDelivery.svg"
                    />
                  </div>
                </div>
              </div>
              <img
                @click.stop="openCartDialog(item.id)"
                src="/assets/eventDetail/template/cart.svg"
                width="60px"
                height="60px"
                style="
                  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
                  cursor: pointer;
                "
              />
            </li>
          </ul>

          <HomeLinkButton
            v-if="curation.data.webLink"
            :link="curation.data.webLink"
          >
            <div class="redirect-button">
              <p style="font-size: 16px; font-weight: 500">상품 전체보기</p>
              <img
                src="/assets/index/redirect-arrow.svg"
                width="20px"
                height="20px"
              />
            </div>
          </HomeLinkButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCart from './dialog/cart/ItemCart.vue'
import { dialogOpenFixed, dialogCloseFixed } from '../config/dialogFixedConfig'
import { auth, realtimeDatabase } from '../config/firebaseConfig'
import EmblaCarousel from 'embla-carousel'
import priceForm from '../filters/priceForm'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import CategoryIcon from './common/CategoryIcon.vue'
import HomeLinkButton from './home/HomeLinkButton.vue'
import { deliveryService } from '../services/delivery.service'
import { homeService } from '../services/home.service'

export default {
  name: 'Index',
  data() {
    return {
      mainBannerList: null,
      mainBannerIndex: 0,
      autoSlideInterval: null,
      mainEmblaApi: null,
      curationList: null,
      dialogScrollSave: 0,
      isFirstgift: null,
      emblaApi: null,
      categoryList: null,
      todayDeliveryGoodsList: [],
      tomorrowDeliveryGoodsList: [],
    }
  },

  components: {
    'category-icon': CategoryIcon,
    HomeLinkButton,
  },

  filters: {
    priceForm,
  },
  computed: {
    goodsDetail() {
      return this.$store.state.goodsDetail
    },
  },
  watch: {
    '$store.getters.getFirstgiftFlag'() {
      this.isFirstgift = this.$store.getters.getFirstgiftFlag
    },
    '$store.state.categoryList'() {
      this.categoryInit()
    },
  },

  created() {
    this.categoryInit()
    this.isFirstgift = this.$store.getters.getFirstgiftFlag

    this.mainBannerList = homeService.getCachedBannerDisplay()
    this.curationList = homeService.getCachedComponent()

    homeService
      .getHomeBannerDisplay()
      .then((items) => {
        if (items) {
          this.mainBannerList = items
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          this.initializeEmbla_banner()
        })
      })

    homeService
      .getHomeComponents()
      .then((items) => {
        if (items) {
          this.curationList = items
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          this.initializeEmbla_component()
        })
      })
    this.initAvailableGoodsList()
  },
  methods: {
    linkToList(category) {
      this.$router.push(`/list?tab=${category}`)
    },
    categoryInit() {
      if (this.$store.state.categoryList) {
        this.categoryList = sortBy(
          filter(this.$store.state.categoryList, ['publish', true]),
          ['order'],
        )
      }
    },
    async initAvailableGoodsList() {
      if (
        this.$store.state.todayDeliveryGoods === null &&
        this.$store.state.tomorrowDeliveryGoods === null
      ) {
        this.$store.dispatch('loadAvailableGoodsList')
      }

      this.todayDeliveryGoodsList = this.$store.state.todayDeliveryGoods || []
      this.tomorrowDeliveryGoodsList =
        this.$store.state.tomorrowDeliveryGoods || []
    },
    mainScrollPrev() {
      this.mainEmblaApi.scrollPrev()
      this.updateMainBannerIndex()
      this.startAutoSlide()
    },
    mainScrollNext() {
      this.mainEmblaApi.scrollNext()
      this.updateMainBannerIndex()
      this.startAutoSlide()
    },
    scrollPrev(index) {
      this.emblaApi[index].scrollPrev()
    },
    scrollNext(index) {
      this.emblaApi[index].scrollNext()
    },
    startAutoSlide() {
      clearInterval(this.autoSlideInterval)
      this.autoSlideInterval = setInterval(() => {
        this.mainScrollNext()
      }, 3000)
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval)
      this.autoSlideInterval = null
    },
    updateMainBannerIndex() {
      if (this.mainEmblaApi) {
        this.mainBannerIndex = this.mainEmblaApi.selectedScrollSnap() + 1
      }
    },
    openCartDialog(target) {
      if (auth.currentUser) {
        this.dialogScrollSave = window.scrollY
        this.$modal.show(
          ItemCart,
          {
            modal: this.$modal,
            productKey: target,
          },
          {
            name: 'dynamic-modal2',
            width: '500px',
            height: '390px',
            transition: 'nice-modal-fade',
            scrollable: true,
          },
          {
            'before-open': (result) => {
              dialogOpenFixed(this.dialogScrollSave)
            },
            closed: (result) => {
              dialogCloseFixed(this.dialogScrollSave)
            },
          },
        )
      } else {
        this.$router.push('login')
      }
    },
    linkToDetail(id) {
      this.$router.push(`/detail?product=${id}`)
    },
    initializeEmbla_banner() {
      const emblaNode = document.querySelector('.embla_main')
      if (!emblaNode) return

      this.mainEmblaApi = EmblaCarousel(emblaNode, { loop: true })
      this.mainEmblaApi
        .on('init', () => {
          this.mainBannerIndex = this.mainEmblaApi.selectedScrollSnap() + 1
        })
        .on('select', () => {
          this.mainBannerIndex = this.mainEmblaApi.selectedScrollSnap() + 1
        })
    },
    initializeEmbla_component() {
      const emblaApi = {}
      this.curationList.forEach((curation, i) => {
        if (
          curation.type === 'home-curation-carousel-image' ||
          curation.type === 'home-curation-carousel-basic'
        ) {
          const emblaNode = document.querySelector(`.embla${i}`)
          if (!emblaNode) return

          emblaApi[i] = EmblaCarousel(emblaNode, {
            skipSnaps: true,
            slidesToScroll: 3,
          })
        }
      })
      this.emblaApi = emblaApi
      this.startAutoSlide()
    },
  },
  beforeDestroy() {
    this.stopAutoSlide()
  },
}
</script>

<style lang="scss" scoped>
* {
  font-family: 'Pretendard';
}

.main-arrow {
  border: none;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0.32);
  width: 56px;
  height: 56px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  top: calc(50% - 28px);

  &:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.42);
  }
}

.curation-arrow {
  position: absolute;
  background-color: white;
  border: none;
  border-radius: 9999px;
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.curation-redirect-arrow {
  background-color: white;
  border: none;
  border-radius: 9999px;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.list-category {
  button {
    border: none;
    background: none;
    cursor: pointer;

    div {
      width: 60px;
      height: 60px;
      border-radius: 9999px;
      background-color: white;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      border: 1.223px solid rgba(0, 0, 0, 0.08);

      i {
        position: absolute;
        right: 1px;
        top: 1px;
      }
    }

    p {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #616161;
    }
  }
}
.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
  gap: 24px;
}

.redirect-button {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  width: 400px;
  height: 52px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.redirect-button-mint {
  width: 115px;
  height: 39px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d7f2f5;
  color: #05a6b4;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    background-color: #ccecf0;
  }

  &:active {
    background-color: #bde4e7;
  }
}
</style>
