<template>
  <a :href="link" @click="handleClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'HomeLinkButton',

  props: {
    link: {
      type: String,
      required: true,
    },
  },

  computed: {
    jygUrl() {
      return 'https://www.jeongyookgak.com'
    },
    isJygUrl() {
      return this.link.startsWith(this.jygUrl)
    },
  },

  methods: {
    handleClick(e) {
      if (this.isJygUrl) {
        e.preventDefault()
        this.$router.push(this.link.replace(this.jygUrl, ''))
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
