import Vue from 'vue'
import Hammer from 'hammerjs'
import VModal from 'vue-js-modal'
import Snackbar from 'vuejs-snackbar'
import App from './App.vue'
import { initSentry } from './lib/sentry'
import { router } from './router'
import { store } from './store'
import './plugins/axios'

Vue.config.productionTip = false

Vue.use(VModal, { dynamic: true })

Vue.component('snackbar', Snackbar)

Vue.directive('swipeleft', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el, {
        touchAction: 'pan-y',
      })
      mc.on('swipeleft', binding.value)
    }
  },
})

Vue.directive('swiperight', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el, {
        touchAction: 'pan-y',
      })
      mc.on('swiperight', binding.value)
    }
  },
})

initVueSystem()

async function initVueSystem() {
  initSentry(router)

  await store.dispatch('clientInit')
  await store.dispatch('preloadData')

  store.dispatch('csrfToken')
  store.dispatch('getServerTime')
  store.dispatch('fetchGoodsList')
  store.dispatch('fetchGoodsDetail')
  store.dispatch('getCategoryList')
  store.dispatch('loadAvailableGoodsList')

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}
