import axios from 'axios'

class DeliveryService {
  async getShippingDates(
    cartData: any,
    postcode: string,
    isMobile: boolean,
    uid: string,
  ) {
    return axios.post('/delivery/shippingdates', {
      carts: cartData,
      postcode: postcode,
      os: isMobile ? 'mobile' : 'pc',
      uid: uid,
    })
  }

  async availableCarts(carts: any, postcode: string, os: string) {
    return axios.post('/delivery/available_carts', {
      carts: carts,
      postcode: postcode,
      os: os,
    })
  }

  async availableGoodsList(postcode: string, shippingDate?: string) {
    return axios.get('/delivery/available-goodslist', {
      params: {
        postcode,
        shippingDate,
      },
    })
  }
}

export const deliveryService = new DeliveryService()
